<template>
  <div class="d-flex flex-column">
    <b-form class="form" v-on:submit.prevent="saveHoliday">
      <!-- begin:date  -->
      <b-form-group :label="$t('HOLIDAY.DATE')" label-cols-lg="2">
        <b-form-datepicker
          id="holiday-date-input"
          class="form-control h-auto"
          v-model="$v.form.date.$model"
          :state="validateState('date')"
        >
        </b-form-datepicker>
      </b-form-group>
      <!-- end:date  -->

      <!-- begin: Holiday     -->
      <b-form-group
        :label="$t('HOLIDAY.HOLIDAY')"
        label-class="text-bolder"
        label-cols-lg="2"
      >
        <b-form-input
          id="holiday"
          class="form-control h-auto"
          v-model="$v.form.holiday.$model"
          :state="validateState('holiday')"
          :placeholder="$t('HOLIDAY.HOLIDAY1')"
        >
        </b-form-input
      ></b-form-group>
      <!-- end: Holiday     -->

      <!-- begin:remark  -->
      <b-form-group
        :label="$t('JOB.REMARK')"
        label-cols-lg="2"
        label-for="holiday-desc-input"
      >
        <b-form-textarea
          id="holiday-desc-input"
          class="form-control h-auto"
          :placeholder="$t('JOB.REMARK')"
          rows="3"
          max-rows="6"
          v-model="$v.form.remark.$model"
          :state="validateState('remark')"
        >
        </b-form-textarea>
      </b-form-group>
      <!-- end:remark  -->
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import required from "vuelidate/lib/validators/required";
import maxLength from "vuelidate/lib/validators/maxLength";
import { mapGetters } from "vuex";

export default {
  name: "HolidayEditForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Holiday Form",
    },
    submit: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {
        return {
          date: "",
          holiday: "",
          remark: "",
        };
      },
    },
  },
  data() {
    return {
      // form: {
      //   date: this.storeForm?.date ?? "",
      //   holiday: this.storeForm?.name,
      //   remark: this.storeForm?.description ?? "",
      // },
      isBusy: false,
    };
  },
  validations: {
    form: {
      date: {
        required,
      },
      holiday: {
        required,
      },
      remark: {
        required,
        maxLength: maxLength(2000),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    saveHoliday() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      const date = this.$v.form.date.$model;
      const holiday = this.$v.form.holiday.$model;
      const remark = this.$v.form.remark.$model;

      const form = {
        date,
        name: holiday,
        description: remark,
      };

      console.log("Store form:", this.storeForm, form);

      this.$emit("save-holiday", form);
    },
    close(response) {
      return response;
    },
  },
  watch: {
    submit() {
      this.saveHoliday();
    },

    /**
     * Assign form
     *
     * @param newForm
     * @param oldForm
     */
    getCompanyHolidayForm(newForm, oldForm) {
      console.log("New Form", newForm, oldForm);

      if (newForm !== null) {
        this.form.holiday = "HAHA!";
        console.log(this.form);
      }
    },
  },
  computed: {
    ...mapGetters(["getCompanyHolidayForm"]),
    ...mapGetters({ storeForm: "getCompanyHolidayForm" }),
  },
};
</script>

<style scoped></style>
