<template>
  <b-modal
    id="manage-holiday-modal"
    footer-bg-variant="light"
    :title="$t('HOLIDAY.MODAL.TITLE', { holiday: name })"
    :ok-disabled="isBusy"
    @ok="engageForm"
    @cancel="
      $bvToast.toast($t('HOLIDAY.FORM.CLOSED'), {
        variant: 'primary',
        title: $t('HOLIDAY.FORM.CANCEL'),
      })
    "
  >
    <AppBgLightAlert>
      {{ $t("HOLIDAY.MODAL.CHANGE") }}
    </AppBgLightAlert>
    <HolidayEditForm
      class="mt-10"
      :submit="isSubmitForm"
      :form="processedForm"
      @save-holiday="updateHoliday($event)"
    ></HolidayEditForm>
  </b-modal>
</template>

<script>
import HolidayEditForm from "@/modules/setting/components/holiday/HolidayEditForm";
import AppBgLightAlert from "@/modules/core/components/utility/info/AppBgLightAlert";
import dayjs from "dayjs";

export default {
  name: "HolidayManagementModal",
  components: { AppBgLightAlert, HolidayEditForm },
  props: {
    name: {
      type: String,
      default: "",
    },
    holiday: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSubmitForm: false,
      processedForm: {
        holiday: "Ayam",
      },
      isBusy: false,
    };
  },
  methods: {
    /**
     * Engage form submission.
     *
     * @param event
     */
    engageForm(event) {
      event.preventDefault();
      console.log("Init child form to begin submit form.");

      this.isSubmitForm = !this.isSubmitForm;
    },

    /**
     * Update the holiday using form.
     *
     * @param form
     */
    updateHoliday(form) {
      console.log("Submitting: ", form);
      form.id = this.holiday.id;

      this.isBusy = true;
      this.$store
        .dispatch("updateCompanyHoliday", { form: form })
        .then((response) => {
          console.log("Success");
          this.$emit("close", response.data);

          this.$bvModal.msgBoxOk(this.$t("HOLIDAY.FORM.SUCCESS").toString(), {
            title: this.$t("HOLIDAY.FORM.SUCCESS").toString(),
          });
        })
        .catch(() => {
          this.$bvModal.msgBoxOk(this.$t("HOLIDAY.FORM.FAILED"), {
            okVariant: "danger",
          });
        })
        .finally(() => (this.isBusy = false));
    },
  },
  watch: {
    holiday() {
      console.log("Processing form.", this.holiday);

      this.processedForm = {
        date: dayjs(this.holiday.date).toDate(),
        remark: this.holiday.description,
        holiday: this.holiday.name,
      };

      console.log(this.processedForm);
    },
  },
};
</script>

<style scoped></style>
