<template>
  <AppPageCard>
    <template #card-title>
      {{ $t("SIDEBAR.HOLIDAY") }}
    </template>

    <template #card-toolbar>
      <div
        class="btn btn-icon btn-light btn-hover-primary mr-3"
        v-on:click="refresh"
      >
        <span class="svg-icon svg-icon-lg svg-icon-primary">
          <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
        </span>
      </div>
      <b-button variant="primary" v-b-toggle.holiday-list-sidebar>{{
        $t("DEPARTMENT.BUTTON")
      }}</b-button>
    </template>

    <AppFieldYearSelector @clickedSearch="setDateFilter"></AppFieldYearSelector>
    <HolidaySettingsTable :reload="reload"></HolidaySettingsTable>

    <b-sidebar
      ref="holiday-list-sidebar-ref"
      id="holiday-list-sidebar"
      backdrop
      right
      lazy
      :title="$t('HOLIDAY.HOLIDAY')"
      sidebar-class="offcanvas"
      header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
      bg-variant="white"
    >
      <template v-slot:header-close>
        <button
          class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
        >
          <i class="ki ki-close icon-xs"></i>
        </button>
      </template>
      <div class="px-10 mt-5">
        <HolidaySettingsForm
          :title="$t('HOLIDAY.CREATE')"
          v-on:close="close"
        ></HolidaySettingsForm>
      </div>
    </b-sidebar>
  </AppPageCard>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import HolidaySettingsForm from "@/modules/setting/components/holiday/HolidaySettingsForm";
import HolidaySettingsTable from "@/modules/setting/components/holiday/HolidaySettingsTable";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import AppFieldYearSelector from "@/modules/core/components/utility/date/AppFieldYearSelector";

export default {
  name: "HolidayListPage",
  components: {
    AppFieldYearSelector,
    AppPageCard,
    HolidaySettingsTable,
    HolidaySettingsForm,
  },
  mounted() {
    console.log("Holiday List");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      {
        title: this.$t("SIDEBAR.DEPARTMENT"),
        route: "/company/department/list",
      },
    ]);
  },
  data() {
    return {
      formData: "",
      reload: false,
    };
  },
  methods: {
    close(data) {
      console.log(data);
      this.formData = "Successfully added holiday";
      console.log(this.formData);

      this.$bvToast.toast(this.formData, {
        title: "Holiday",
        variant: "success",
      });

      const sideBar = this.$refs["holiday-list-sidebar-ref"];
      sideBar.hide();

      this.refresh();
    },

    refresh() {
      this.reload = !this.reload;
    },

    /**
     * Change year date.
     *
     * @param date
     */
    setDateFilter(date) {
      console.log("Date ", date);

      this.$store.dispatch("changeHolidayDateFilter", { date: date });
      this.$store.dispatch("fetchCompanyHolidays");
    },
  },
};
</script>

<style scoped></style>
